.toggleTitle {
	padding: 10px;
	position: relative;
	&:after {
		content: "\f107";
		font-family: FontAwesome;
		position: absolute;
		right: 10px;
	}
	&:before {
		font-family: FontAwesome;
		position: absolute;
		left: 2px;
		color: #fff;
		border-radius: 2px;
	}
	&.is-open {
		&:after {
			content: "\f106";
		}
	}
	&.lower-triangle {
		&:before {
			content: '';
			width: 0;
			height: 0;
			position: absolute;
			top: 103%;
			left: 46%;
			border: 10px solid transparent;
			border-top: 0;
			border-top: 6px solid #001c58;
		}
	}
    &-research {
        text-align: center;
		margin: 20px auto 0;
		box-sizing: border-box;
		color: #fff;
        background-color: #000;
        &:before {
    	    @extend .button-submit:before;
            position: absolute;
            left: 10px;
        }
  }
	&-service {
		&:before {
			content: "";
			width: 15px;
            height: 12px;
			padding: 5px;
            background: url(/common/img/icon_detail_base.png) $color_navy 5px 5px no-repeat;
            background-size: 15px;
		}
	}
	&-wash {
		&:before {
			content: "";
			width: 15px;
            height: 12px;
			padding: 5px;
            background: url(/common/img/icon_car_wash_service_small.png) $color_navy 2px 4px no-repeat;
            background-size: 20px;
		}
	}
	&-drive {
		&:before {
			content: "";
			width: 15px;
            height: 12px;
			padding: 5px;
            background: url(/common/img/icon_self_wash_small.png) $color_navy 2px 4px no-repeat;
            background-size: 21px;
		}
	}
	&-dafault {
		background-color: #0c52a3;
	}
}

.toggleTitleShopComment {
	color: #0c52a3;
	border: 1px solid #b7c2de;
	background-color: #dfe4ef;
}

.toggleTitleNavy {
	color: #fff;
	border-bottom: 2px solid #001c58;
	background-color: #0c52a3;
}

.toggleBgWhite {
	color: #042863;
	background-color: #fff;
}

.toggleTitleWhite {
	color: #0c52a3;
	border: 1px solid #ccc;
	background-color: #fff;
}

.toggleTitleGray  {
	color: #0c52a3;
	background-color: #f2f2f2;
	span {
		padding-left: 20px;
	}
}

.priceList {
	&:before {
		content: "";
		width: 15px;
		height: 12px;
		border-radius: 2px;
	}
}

.toggleHand {
	&:before {
		content: "";
		padding: 5px;
		background: url(/common/img/icon_hand_wash.png) $color_navy 4px 4px no-repeat;
		background-size: 17px;
	}
}

.toggleCoating {
	&:before {
		content: "";
		padding: 5px;
		background: url(/common/img/icon_coating.png) $color_navy 4px 4px no-repeat;
		background-size: 17px;
	}
}

.toggleDrive {
	&:before {
		content: "";
		padding: 5px;
		background: url(/common/img/icon_self_wash.png) $color_navy 2px 4px no-repeat;
		background-size: 22px;
	}
}

.toggleStaff {
	&:before {
		content: "";
		padding: 5px;
		background: url(/common/img/icon_staff_wash.png) $color_navy 4px 5px no-repeat;
		background-size: 20px;
	}
}

.toggleRoom {
	&:before {
		content: "";
		padding: 5px;
		background: url(/common/img/icon_indoor_cleaning.png) $color_navy 4px 5px no-repeat;
		background-size: 17px;
	}
}

.no-comment {
	padding: 10px;
	color: #fff;
	background-color: #ababab;
	border: 1px solid #969696;
	&-bottom {
		margin-bottom: 10px;
	}
    &:after {
        content: "";
    }
}
