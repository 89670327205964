.modal {
    display: none;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.7);
    .window {
        width: 95%;
        max-height: 95%;
        position: absolute;
        z-index: 10001;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border: 3px solid $color_navy;
        padding: 12px;
        background: #fff;
        box-sizing: border-box;
        overflow: scroll;
        @media #{$PC} {
            width: 500px;
        }
        h1 {
            text-align: center;
            font-size: $px16;
            color: $color_navy;
            font-weight: bold;
            border-bottom: 1px solid #CCC;
            margin-bottom: 10px;
        }
        .login_field {
            padding: 0 15px;
            margin-bottom: 10px;
            position: relative;
            font-size: $px12;
            min-height: 20px;
            dt {
                color: $color_navy;
                font-weight: bold;
            }
            dd {
                margin-bottom: 8px;
                input {
                    border: 2px solid $color_navy;
                    background: #F8F8EF;
                    padding: 3px;
                    font-size: $px18;
                    line-height: 1.6;
                    width: 100%;
                }
            }
            .autoLogin {
                &:before {
                    left: 30px;
                }
                &:after {
                    left: 30px;
                }
            }
        }
        .forgotPass {
            margin-bottom: 20px;
        }
        .fieldCenter {
            text-align: center;
        }
        .snsLoginBlock {
            margin-bottom: 15px;
        }
        .login_btn {
            position: relative;
            width: 95%;
            margin: 0 auto 10px;
            padding: 5px 0;
            border: none;
            background: $color_navy;
            font-size: $px20;
            color: #FFF;
            &:after {
                content: "\f0a9";
                display: block;
                font-size: 28px;
                height: 28px;
                width: 28px;
                line-height: 28px;
                font-family: FontAwesome;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 13px;
                margin: auto;
                color: #FFF;
            }
        }
        .register {
            text-align: center;
            .button {
                width: 95%;
                margin: 0 auto;
                padding: 10px;
                background: $color_navy;
                color: #FFF;
                font-size: $px20;
                span {
                    vertical-align: text-top;
                    font-size: 12px;
                    line-height: 1.5;
                    display: inline-block;
                    background: #FFF;
                    color: #444;
                    padding: 0 4px;
                    margin-right: 9px;
                    em {
                        font-size: 16px;
                        color: #D80000;
                    }
                }
            }
        }
        .reissue_btn {
            width: 95%;
            margin: 0 auto;
            padding: 5px 0;
            display: block;
            border: none;
            background: $color_navy;
            font-size: $px20;
            color: #FFF;
        }
        #modal_close {
            display: block;
            position: absolute;
            right: 6px;
            top: 6px;
            text-align: center;
            width: 26px;
            height: 26px;
            line-height: 26px;
            font-size: 16px;
            background: $color_navy;
            color: #FFF;
        }
        #reissue {
            display: none;
        }
        .forgot {
            color: $color_navy;
            text-decoration: underline;
        }
    }
}

.commitmentModal {
    width: 95%;
    #modal_close {
        top: 5px;
        right: 5px;
        line-height: 26px;
        z-index: 10005;
    }
    .window {
        border: 0;
        .searchTitle {
            font-size: 1em;
            margin-bottom: 10px;
            padding-bottom: 5px;
            border-bottom: 1px solid #ccc;
        }
        >ul {
            margin-bottom: 20px;
            padding: 0 10px 0 10px;
            li {
                margin-bottom: 5px;
                position: relative;
                color: #000;
                border-bottom: 1px dashed #ccc;
                label {
                    padding: 0;
                    font-weight: normal;
                }
            }
        }
    }
}

.circleCloseBtn {
    display: block;
    position: absolute;
    text-align: center;
    width: 26px;
    height: 26px;
    font-size: 18px;
    background: #555;
    color: #FFF;
    border-radius: 25px;
    border: 2px solid #fff;
}

.isModal {
    .overlay {
        visibility: visible;
        background: rgba(0, 0, 0, 0.7);
        z-index: 10000;
    }
}

.no-position {
    position: inherit;
}

.none {
    display: none;
}
