.button {
    &-base {
        padding: 10px;
        color: #fff;
        font-size: 0.9rem;
        border: none;
        box-sizing: border-box;
        position: relative;
        display: block;
        @media #{$PC} {
            font-size: $px16;
        }
        &:before {
            display: block;
            font-family: FontAwesome;
            position: absolute;
            line-height: 28px;
        }
        &:after {
            display: block;
            font-family: FontAwesome;
            position: absolute;
            line-height: 28px;
        }
        >.standOut {
    		font-weight: bold;
    	}
    }
    &-black {
        background-color: #000;
    }
    &-normal {
        width: 100%;
    }
    &-link {
        width: 80%;
        margin: 0 auto;
        padding: 10px;
        display: block;
        text-align: center;
        background-color: $color_navy;
        box-shadow: #042863 0 3px 0 0;
    }
    &-gps {
        width: 90%;
        display: block;
        margin: 0 auto;
        border-bottom: 2px solid #832720;
        background-color: #ff0000;
        &:before {
            content: "\f041";
            font-size: 1.3rem;
            top: 0;
            left: 15px;
            bottom: 0;
            margin: auto;
            width: 12px;
            height: 28px;
        }
        &:after {
            content: "\f105";
            font-size: 1.3rem;
            top: 0;
            right: 15px;
            bottom: 0;
            margin: auto;
            width: 8px;
            height: 28px;
        }
    }
    &-submit {
        width: 80%;
        margin: 0 auto 20px;
        display: block;
        text-align: center;
        background-color: #393939;
        box-shadow: #000 0 3px 0 0;
        &:before {
            content: "\f002";
            font-size: 1rem;
            top: 0;
            left: 10px;
            font-family: FontAwesome;
            bottom: 0;
            margin: auto;
            width: 14px;
            height: 28px;
        }
        &:after {
            content: "\f105";
            font-size: 1.3rem;
            top: 0;
            right: 15px;
            bottom: 0;
            margin: auto;
            width: 8px;
            height: 28px;
        }
    }
    &-delete {
        width: 80%;
        margin: 0 auto 20px;
        display: block;
        font-size: 0.9rem;
        text-align: center;
        background-color: #393939;
        box-shadow: #000 0 3px 0 0;
        &:before {
            content: "\f1f8";
            font-size: 1.3rem;
			left: 15px;
            margin: auto;
			font-family: FontAwesome;
        }
    }
    &-send {
        width: 80%;
        margin: 0 auto;
        padding: 10px;
        display: block;
        background-color: $color_navy;
        box-shadow: #042863 0 3px 0 0;
        &:before {
            content: "";
            background: url(../../common/img/icon_customer_regist.png) 0 0 no-repeat;
            background-size: cover;
            width: 23px;
            height: 22px;
            top: 0;
            left: 15px;
            bottom: 0;
            margin: auto;
        }
        &:after {
            content: "\f105";
            font-size: 1.3rem;
            top: 0;
            right: 15px;
            bottom: 0;
            margin: auto;
            width: 8px;
            height: 28px;
        }
    }
    &-review {
        padding: 20px 10px 0 10px;
        border-bottom: 2px solid #042863;
        background-color: $color_navy;
        position: relative;
        &:before {
            position: absolute;
            content: '';
            background: url(/common/img/icon_review01.png) center 0 no-repeat;
            background-size: 40px;
            left: 0;
            right: 0;
            margin: auto;
            top: 5px;
            width: 100%;
            height: 15px;
        }
    }
    &-back {
        &:before {
            content: "\f104";
        }
        &:after {
            content: "";
        }
    }
    &-BorderNavy {
        color: #234f8a;
        border: 1px solid #234f8a;
        box-shadow: 0 2px 0 #234f8a;
    }
    &-changePasswd {
        &:before {
            content: "";
            width: 15px;
            height: 20px;
            margin: auto;
            top: 0;
            bottom: 0px;
            left: 5px;
            background: url(/common/img/icon_change_passwd.png) 0 0 no-repeat;
            background-size: cover;
        }
    }
    &-changeProfile {
        &:before {
            content: "";
            width: 25px;
            height: 15px;
            margin: auto;
            top: 3px;
            bottom: 0px;
            left: 5px;
            background: url(/common/img/icon_change_profile.png) 0 0 no-repeat;
            background-size: contain;
        }
    }
    &-regist {
        &:before {

        }
    }
    &-news {
        &:before {
            content: "";
            background-image: url(/common/img/icon_news.png);
            background-size: cover;
            width: 25px;
            height: 25px;
        }
    }
    &-sendReview {
        width: 80%;
        margin: 0 auto 20px;
        background-color: #0c52a3;
        box-shadow: #000 0 3px 0 0;
        // &:before {
        //     content: "";
        //     background-image: url(/common/img/icon_review_pencil.png);
        //     background-size: cover;
        //     width: 43px;
        //     height: 18px;
        // }
    }
    &-search {
        width: 80%;
        margin: 0 auto 20px;
        display: block;
        font-size: 0.9rem;
        text-align: center;
        background-color: #0c52a3;
        box-shadow: #000 0 3px 0 0;
        &:before {
            content: "\f002";
            font-size: 1rem;
            top: 7px;
			left: 10px;
			font-family: FontAwesome;
        }
    }
    &-searchCommitment {
        padding: 10px;
        margin-bottom: 10px;
        color: #0c52a3;
        font-weight: bold;
        border: 1px solid #ccc;
        background-color: #fff;
    }
}
