.select {
    &-base {
        appearance: none;
        border: 1px solid #DDD;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: inset 1px 1px 1px rgba(0,0,0,.2);
        select {
            width: 100%;
            padding: 5px 10px 5px 10px;
            position: relative;
            z-index: 1;
            appearance: none;
            background: none transparent;
            border-radius: 0;
            border: none;
            font-size: $px14;
            line-height: 1.6;
            &::-ms-expand {
                display: none;
            }
        }
    }
    &-triangle {
        position: relative;
        &:after {
    		content: '▼';
    		display: block;
    		font-size: 12px;
    		height: 18px;
    		width: 18px;
    		position: absolute;
    		top: 0;
    		bottom: 0;
    		right: 0;
            margin: auto;
    	}
    }
    &-width30 {
        width: 30%;
    }
    &-width40 {
        width: 40%;
    }
}
