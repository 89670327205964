.pagenation {
    padding: 10px 0;
    >ul {
        display: flex;
        justify-content: center;
        >li {
            >a {
                display: block;
                background: #AAA;
                width: 20px;
                color: #FFF;
                text-align: center;
                margin: 0 3px;
            }
            &.current {
                >a {
                    background: #042863;
                }
            }
        }
    }
}
