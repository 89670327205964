.headline {
    &-base {
        margin-bottom: 10px;
        position: relative;
        box-sizing: border-box;
        @media #{$PC} {
            font-size: $px16;
        }
        &:before {
            content: "";
            position: absolute;
            font-family: FontAwesome;
        }
        .name  {
            padding-left: 30px;
        }
        .locationSearchTitle {
            padding-left: 40px;
        }
    }
    &-baseWithBgColor {
        padding: 10px;
        color: #fff;
        background-color: #518dca;
    }
    &-bottomNone {
        margin-bottom: 0;
    }
    &-features {
        &:before {
            background: url(/common/img/icon_feature_headline.png) 0 0 no-repeat;
            background-size: cover;
            width: 22px;
            height: 18px;
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }
    &-washguide {
        &:before {
            background: url(/common/img/icon_wash_guide_headline.png) 0 0 no-repeat;
            background-size: cover;
            width: 21px;
            height: 25px;
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }
    &-customer {
        &:before {
            background-image: url(/common/img/icon_customer_service.png);
            background-size: cover;
            width: 25px;
            height: 25px;
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }
    &-news {
        display: flex;
        justify-content: space-between;
        &:before {
            background-image: url(/common/img/icon_news.png);
            background-size: cover;
            width: 25px;
            height: 25px;
            top: 0;
            bottom: 0;
            margin: auto;        }
    }
    &-locationSearch {
        color: #0c52a3;
        line-height: 1.2;
        padding: 10px;
        background-color: #fff;
        &:before {
            @extend .button-gps:before;
            content: "\f041";
            top: 3px;
        }
    }
    &-service {
    	display: flex;
    	flex-direction: row;
        font-size: 0.9rem;
        @media #{$PC} {
            font-size: $px16;
        }
        &:before {
            font-size: 0.7rem;
            width: 32px;
            height: 25px;
            @media #{$PC} {
                font-size: inherit;
            }
        }
        >em {
            padding-left: 37px;
            font-size: 1rem;
            font-weight: bold;
            @media #{$PC} {
                font-size: inherit;
            }
        }
        >span {
            margin-left: auto;
        }
    }
    &-serviceTopPage {
        padding: 0 10px 0 10px;
        color: $color_navy;
        &:before {
            background: url(/img/ico_wash_service_top.png) 0 0 no-repeat;
            background-size: cover;
        }
    }
    &-serviceLowerPage {
        color: #fff;
        &:before {
            background: url(/common/img/icon_car_wash_service_white.png) 0 0 no-repeat;
            background-size: cover;
        }
    }
    &-commitment {
        color: $color_navy;
        &:before {
            width: 20px;
            height: 20px;
            background: url(/common/img/icon_commitment.png) 0 0 no-repeat;
            background-size: cover;
        }
        >em {
            padding-left: 25px;
            font-weight: bold;
            font-size: 1rem;
        }
    }
    &-detailWithBgColor {
        padding: 5px;
        color: $color_navy;
        font-weight: bold;
        background-color: #f2f2f2;
    }
    &-detailBase {
        span {
            padding-left: 30px;
        }
        &:before {
            padding: 5px;
            border-radius: 2px;
        }
    }
    &-detailDefault {
        &:before {
            width: 15px;
            height: 12px;
            background: url(/common/img/icon_detail_base.png) $color_navy 5px 5px no-repeat;
            background-size: 15px;
        }
    }
    &-coupon {
        &:before {
            width: 15px;
            height: 12px;
            background: url(/common/img/icon_coupon.png) #ff0000 5px 7px no-repeat;
            background-size: 15px;
        }
    }
    &-goodValue {
        &:before {
            width: 17px;
            height: 17px;
            background: url(/common/img/icon_nice.png) 0 0 no-repeat;
            background-size: cover;
        }
    }
    &-yen {
        &:before {
            width: 20px;
            height: 20px;
            background: url(/common/img/icon_yen.png) 0 0 no-repeat;
            background-size: cover;
        }
    }
    &-shopService {
        &:before {
            width: 22px;
            height: 18px;
            background: url(/common/img/icon_detail_base.png) 0 0 no-repeat;
            background-size: cover;
        }
    }
    &-fcolorRed {
        color: #ff0000;
        font-weight: bold;
    }
    &-sitemap {
        &:before {
            width: 25px;
            height: 25px;
            background: url(/common/img/icon_title_sitemap.png) 0 0 no-repeat;
            background-size: cover;
        }
    }
    &-terms {
        &:before {
            width: 25px;
            height: 25px;
            background: url(/common/img/icon_title_terms_of_service.png) 0 0 no-repeat;
            background-size: cover;
        }
    }
    &-privacy {
        &:before {
            width: 22px;
            height: 28px;
            background: url(/common/img/icon_title_privacy.png) 0 0 no-repeat;
            background-size: cover;
        }
    }
    &-agreement {
        &:before {
            width: 25px;
            height: 25px;
            background: url(/common/img/icon_title_agreement.png) 0 0 no-repeat;
            background-size: cover;
        }
    }
    &-gulidline {
        &:before {
            width: 25px;
            height: 23px;
            background: url(/common/img/icon_title_guide.png) 0 0 no-repeat;
            background-size: cover;
        }
    }
    &-regist {
        &:before {
            width: 23px;
            height: 22px;
            top: 0;
            bottom: 0;
            left: 13px;
            margin: auto;
            background: url(/common/img/icon_customer_regist.png) 0 0 no-repeat;
            background-size: cover;
        }
    }
    &-mail {
        &:before {
            width: 26px;
            height: 19px;
            top: 0;
            bottom: 0;
            left: 8px;
            margin: auto;
            background: url(/common/img/icon_mail_white.png) 0 0 no-repeat;
            background-size: cover;
        }
    }
    &-sendReview {
        .name {
            padding-left: 50px;
        }
        &:before {
            width: 43px;
            height: 18px;
            top: 0;
            bottom: 0;
            left: 8px;
            margin: auto;
            background: url(/common/img/icon_review_pencil.png) 0 0 no-repeat;
            background-size: cover;
        }
    }
}
