.unav {
    &-list {
        display: flex;
    }
    &-item {
        border-left: 1px solid #CCC;
        &:first-child {
            border-left: none;
        }
        >a {
            display: block;
            width: 50px;
            height: 10px;
            font-size: 2vw;
            text-align: center;
            padding: 30px 0 5px;
            color: $color_navy;
            position: relative;
            @media #{$Nexus7} {
                width: 100px;
                height: 20px;
                font-size: inherit;
                padding: 35px 0 5px;
            }
            &:before {
                content: '';
                position: absolute;
            }
        }
        &.unavi {
            &-login {
                >a {
                    &:before {
                        background: url('../img/ico_login01.png') 0 0 no-repeat;
                        background-size: cover;
                        top: 6px;
                        width: 25px;
                        height: 20px;
                        left: 0;
                        right: 0;
                        margin: auto;
                        @media #{$Nexus7} {
                            top: 6px;
                        }
                    }
                }
            }
            &-signup {
                >a {
                    &:before {
                        background: url('../img/ico_regist01.png') 0 0 no-repeat;
                        background-size: cover;
                        top: 2px;
                        width: 25px;
                        height: 24px;
                        left: 0;
                        right: 0;
                        margin: auto;
                        @media #{$Nexus7} {
                            top: 6px;
                        }
                    }
                }
            }
            &-menu {
                >a {
                    &:before {
                        background: url('../img/ico_menu01.png') 0 0 no-repeat;
                        background-size: cover;
                        top: 2px;
                        width: 23px;
                        height: 23px;
                        left: 0;
                        right: 0;
                        margin: auto;
                        @media #{$Nexus7} {
                            top: 6px;
                        }
                    }
                }
            }
            &-mypage {
                >a {
                    &:before {
                        background: url('../img/ico_mypage01.png') 0 0 no-repeat;
                        background-size: cover;
                        top: 2px;
                        width: 28px;
                        height: 28px;
                        left: 0;
                        right: 0;
                        margin: auto;
                        @media #{$Nexus7} {
                            top: 6px;
                        }
                    }
                }
            }
            &-logout {
                >a {
                    &:before {
                        background: url('../img/ico_logout01.png') 0 0 no-repeat;
                        background-size: cover;
                        top: 6px;
                        width: 25px;
                        height: 20px;
                        left: 0;
                        right: 0;
                        margin: auto;
                        @media #{$Nexus7} {
                            top: 6px;
                        }
                    }
                }
            }
        }
    }
}
