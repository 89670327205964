@import "./utilities/reset";

/* Base CSS
---------------------------------------------*/
html {
  width: 100%;
}

body {
  color: $font_color;
  text-align: left;
  background: #FFF;
  width: 100%;
  height: 100%;
  font-size: $font_size;
  line-height: $line_height;
  font-family: $sans_serif;
  -webkit-text-size-adjust: 100%;
}

img {
  vertical-align: bottom;
  max-width: 100%;
}

a {
  color: $link_color;
  text-decoration: none;
}

dt {
  font-weight: bold;
}

th {
  font-weight: bold;
  padding: 3px;
}

td {
  padding: 3px;
}

button {
    &:focus {
        outline: none;
    }
}

@import "input";
@import "select";
