input {
    &.tabCheck {
        display: none;
    }
    &[type=checkbox] {
        appearance: none;
        visibility: hidden;
        display: block;
        + label {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            padding: 0 20px 0 60px;
            box-sizing: border-box;
            font-size: 4vw;
            display: flex;
            align-items: center;
            @media #{$Nexus7} {
                font-size: inherit;
            }
            > span {
                line-height: 1.2;
            }
            &:before {
                content: '';
                position: absolute;
                width: 20px;
                height: 20px;
                top: 0;
                bottom: 0;
                right: 5px;
                margin: auto 0;
                display: block;
                background: #FFF;
                box-shadow: inset 2px 2px 2px rgba(0,0,0,.2);
                border-radius: 2px;
            }
            &:after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto 0;
                right: 7px;
                width: 15px;
                height: 15px;
            }
        }
        &:checked {
            + label {
                &:after {
                    background: url(../img/icon_check.png) 0 0 no-repeat;
                    background-size: contain;
                }
            }
            + .checkRed {
                &:after {
                    background: url(../img/icon_check_red.png) 0 0 no-repeat;
                    background-size: contain;
                }
            }
        }
    }
    &[type=radio] {
        display: none;
        &+label{
            display: inline-block;
            position: relative;
            cursor: pointer;
            margin-left: 20px;
            padding: 10px 20px;
            text-align: center;
            color: #666;
            line-height: 1;
            &:before{
                position: absolute;
                content: "";
                top: 50%;
                left: -10px;
                width: 20px;
                height: 20px;
                margin-top: -10px;
                background: #fff;
                border-radius: 50%;
                transition: .2s;
                border: 1px solid #555;
            }
            &:after {
                position: absolute;
                content: "";
                top: 50%;
                left: 0;
                width: 0;
                height: 0;
                margin-top: 0;
                transition: .2s;
                background: #555;
            }
            &:focus {
                outline: none;
            }
            &:hover {
                &:before {
                    background: #fff;
                }
            }
        }
        &:checked {
            &+label {
                &:after {
                    top: 50%;
                    left: -4px;
                    width: 10px;
                    height: 10px;
                    margin-top: -4px;
                    border-radius: 50%;
                }
            }
        }
    }
    &[type=text] {
        padding: 10px;
    	appearance: none;
        box-sizing: border-box;
        border: 1px solid #DDD;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: inset 1px 1px 1px rgba(0,0,0,.2);
        font-size: $px14;
    }
    &[type=password] {
        @extend input[type=text];
    }
    &[type=email] {
        @extend input[type=text];
    }
    &[type=file] {
        border: 1px solid #234f8a;
        background-color: #FFF;
        box-shadow: 0 2px 0 #234f8a;
        font-size: 4vw;
        @media #{$Nexus7} {
            font-size: inherit;
        }
    }
}

textarea {
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #DDD;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: inset 1px 1px 1px rgba(0,0,0,.2);
    font-size: $px14;
}
