@import "setting";
@import "mixin";
@import "base";
@import "../../components/header";

#breadcrumbs {
    display: none;
}

.pagetopLink {
    height: 58px;
    margin-bottom: 20px;
    @media #{$PC} {
        width: 65px;
        position: absolute;
        top: -80px;
        right: 10px;
        margin-bottom: 0;
    }
    p {
        position: relative;

        > a {
            padding: 10px 10px 5px;
            position: absolute;
            top: 20px;
            right: 10px;
            display: inline-block;
            color: #fff;
            box-shadow: 2px 2px #b8bdc7;
            background-color: #63bceb;

            img {
                vertical-align: middle;
            }

            .linkTitle {
                display: block;
                font-size: 0.5rem;
            }
        }
    }
}

.headerTop {
    padding-top: 72px;
    @media #{$Nexus7} {
        padding-top: 87px;
    }
}

#subPage {
    @media #{$PC} {
        width: $main_width;
        margin: 0 auto;
        min-height: 400px;
        display: block;
    }
}

.headerTop-bgGray {
    background-color: #eee;
}
@import "../../components/side_menu";
@import "../../components/button";
@import "../../components/headline";
@import "../../components/toggle";
@import "../../components/modal";
@import "../../components/footer";

.page {
    &-top {
        @media #{$PC} {
            width: $main_width;
            margin: 0 auto;
            display: block;
        }
    }
}

.snsLogin {
    width: 90%;
    margin: 0 auto 20px;
    color: #fff;
    border: 2px solid #0baced;
    background-color: #f6f6f6;
    @media #{$PC} {
        width: 400px;
    }
    &-title {
        padding: 3px 0;
        text-align: center;
        background-color: #0baced;
    }

    &-list {
        padding: 10px 3%;
        display: flex;
        justify-content: space-around;
    }

    &-item {
        flex-basis: 16%;
    }
}

.errorMessage {
    color: #ff0000;
}

.error {
    @extend .errorMessage;
}

.errorUpperMessage {
    padding: 0 10px;
}

.registerComplete {
    padding: 10px;
}

.formBtn {
    margin-bottom: 20px;
}

@import "../../components/pagenation";
// .editReview {
//     position: relative;
//     &:before {
//         content: "";
//         width: 15px;
//         height: 15px;
//         position: absolute;
//         top: 5px;
//         left: 45%;
//         background: url(/common/img/icon_pencil.png) 0 0 no-repeat;
//         background-size: cover;
//         z-index: 1;
//     }
// }
