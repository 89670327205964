.footer {
    background-color: #e8f3ff;
    @media #{$PC} {
        background-color: #042863;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 70px;;
    }
    &-prefSearch {
        padding: 10px 0 10px 35px;
        border-bottom: 1px solid #fff;
        background-repeat: no-repeat;
        background-image: url(../img/ico_pref_search.png);
        background-position: 5px center;
        font-size: 5vw;
        @media #{$Nexus7} {
            font-size: inherit;
        }
        @media #{$PC} {
            font-size: $px16;
        }
    }
    &-prefNav {
        color: #fff;
        background-color: #0c52a3;
        @media #{$PC} {
            width: 800px;
            background: none;
        }
        a {
            font-size: 4vw;
            color: #fff;
            @media #{$Nexus7} {
                font-size: inherit;
            }
        }
        >ul {
            border-bottom: 1px solid #fff;
            @media #{$PC} {
                border-bottom: none;
                margin-bottom: 20px;
            }
            >li {
                margin: 0 10px 0 10px;
                border-bottom: 1px dotted #fff;
                @media #{$PC} {
                    display: flex;
                    margin-bottom: 5px;
                }
                >a {
                    padding: 5px 10px 5px 0;
                }
        		>ul {
                    display: flex;
                    flex-wrap: wrap;
                    @media #{$PC} {
                        margin-left: 10px;
                    }
        			>li {
        				margin: 0;
                        margin-bottom: 5px;
                        border-bottom: 0;
                        &:last-child {
                            >a {
                                border: none;
                            }
                        }
        				>a {
        	                margin-right: 5px;
        	                padding-right: 5px;
        	                display: block;
        	                border-right: 1px solid #fff;
                            @media #{$PC} {
                                margin: 0;
                                padding: 5px 10px;
                            }
        	            }
        			}
                }
            }
        }
    }
    &-prefLink {
        font-weight: bold;
    }
    &-subNav {
        background-color: #0c52a3;
        @media #{$PC} {
            width: 280px;
            background: none;
            border-bottom: none;
            padding: 5px 0;
            margin-bottom: 20px;
        }
        >ul {
            display: flex;
            flex-wrap: wrap;
            >li {
                width: 49.8%;
                border-bottom: 1px solid #fff;
                @media #{$PC} {
                    width: 100%;
                    border-bottom: none;
                }
                >a {
                    color: #fff;
                    font-size: 4vw;
                    background: #042863;
                    padding: 10px;
                    display: block;
                    position: relative;
                    @media #{$Nexus7} {
                        font-size: inherit;
                    }
                    @media #{$PC} {
                        padding: 5px 0 5px 30px;
                        font-size: $px16;
                    }
                    &:after {
                        content: "\f105";
                        display: block;
                        font-size: 0.9rem;
                        height: 30px;
                        width: 28px;
                        line-height: 28px;
                        font-family: FontAwesome;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        margin: auto;
                        transition: all 0.2s;
                        text-align: center;
                        @media #{$PC} {
                            right: auto;
                            left: 0;
                        }
                    }
                }
                &:nth-child(odd) {
                    border-right: 1px solid #fff;
                    @media #{$PC} {
                        border: none;
                    }
                }
                &:nth-last-child(2) {
                    border-bottom: 0;
                    @media #{$PC} {
                        border: none;
                    }
                }
                &:last-child {
                    border: none;
                }
            }
        }
    }
    &-copyright {
        text-align: center;
        background-color: #fff;
        @media #{$PC} {
            flex-basis: 100%;
        }
        >img {
            width: 90%;
            height: auto;
        }
        >p {
            font-size: 70%;
        }
        >a {
            color: #000;
        }
    }
    &-logo {
        padding: 15px;
    }
}
