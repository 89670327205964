.header {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 9999;
    border-bottom: 1px solid $color_navy;
    background: #FFF;
    &-bar {
        background: #042863;
    }
    &-title {
        padding: 5px 0 5px 10px;
        font-size: 0.6rem;
        color: #FFF;
        @media #{$Nexus7} {
            font-size: inherit;
            padding: 3px 0 3px 10px;
        }
    }
    &-main {
        display: flex;
        justify-content: space-between;
    }
    &-logo {
        margin: 8px 0 0 8px;
        >a {
            >img {
                @media #{$Nexus7} {
                    width: auto;
                    height: 48px;
                }
            }
        }
    }
    &-nav {
    }
}

@import "./unav";
