$px10:72%;
$px11:79%;
$px12:86%;
$px13:93%;
$px14:100%;
$px15:108%;
$px16:115%;
$px17:122%;
$px18:129%;
$px19:136%;
$px20:143%;
$px21:150%;
$px22:158%;
$px23:165%;
$px24:172%;
$px25:179%;
$px26:186%;
$px27:193%;
$px28:200%;
$px29:208%;
$px30:215%;
$px31:222%;
$px32:229%;
$px33:236%;
$px34:243%;
$px35:250%;
$px36:258%;
$px37:265%;
$px38:272%;
$px39:279%;
$px40:286%;
$px41:293%;
$px42:300%;
$px43:308%;
$px44:315%;
$px45:322%;
$px46:329%;
$px47:336%;
$px48:343%;
$px49:350%;
$px50:358%;
$px51:365%;
$px52:372%;
$px53:379%;
$px54:386%;
$px55:393%;
$px56:400%;
$px57:408%;
$px58:415%;
$px59:422%;
$px60:429%;
$px61:436%;
$px62:443%;
$px63:450%;
$px64:458%;
$px65:465%;
$px66:472%;
$px67:479%;
$px68:486%;
$px69:493%;
$px70:500%;
$px71:508%;
$px72:515%;