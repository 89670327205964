// Utilities
@import "./utilities/font-size/ptp-14";

// Font Awesome
@import "./utilities/font-awesome/font-awesome";

// Slick
@import "./utilities/slick/slick";
@import "./utilities/slick/slick-theme";
// Swipebox
@import "./utilities/swipebox/swipebox";
#swipebox-close {
    z-index: 10010;
    top: 70px;
}

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}
@font-face {
  font-family: "Helvetica Neue";
  src: local("Helvetica Neue Regular");
  font-weight: 100;
}
@font-face {
  font-family: "Helvetica Neue";
  src: local("Helvetica Neue Regular");
  font-weight: 200;
}

// Variables
$font_size: 14px;
$base_color: #FFF;
$line_height: 1.6;
$main_width: 1080px;
$font_color: #333;
$link_color: #333;
$color_navy: #0C52A3;

$serif: "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
$sans_serif: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Yu Gothic", YuGothic, "メイリオ", "Meiryo", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", Sans-Serif;
$maru_gothic: "ヒラギノ丸ゴ Pro W4","ヒラギノ丸ゴ Pro","Hiragino Maru Gothic Pro","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","HG丸ｺﾞｼｯｸM-PRO","HGMaruGothicMPRO";

// Breakpoints
$iPhone5:  "only screen and (min-width:321px)"; // Based on iPhone5
$SP:  "only screen and (min-width:376px)"; // Based on iPhone6
$Phablet:  "only screen and (min-width:377px)"; // Based on iPhone6+ + alfa
$Nexus7:  "only screen and (min-width:462px)"; // Based on Nexus7
$Tablet:  "only screen and (min-width:601px)"; // Based on iPad Air & mini + alfa
$PC: "only screen and (min-width:1080px)";
