.sideMenu {
    position: fixed;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    text-align: left;
    background: #fff;
    z-index: 1;
    overflow: scroll;
    >dl {
        >dd {
            >ul {
                li {
                    a {
                        padding: 10px;
                        display: block;
                        border-bottom: 1px solid #518dcb;
                    }
                }
            }
        }
    }
    .menuTitle {
        padding: 10px;
        color: #fff;
        font-weight: normal;
        background-color: #518dcb;
    }
    .sideMenuLogo {
        height: 62px;
        >a {
            img {
                padding: 10px 10px 0;
            }
        }
    }
    .transition {
        position: relative;
        display: block;
        .innerText {
            padding-left: 30px;
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
        }
        &:after {
            content: "\f105";
            display: block;
            position: absolute;
            top: 6px;
            right: 10px;
            font-family: FontAwesome;
            font-size: 1.3rem;
            line-height: 28px;
        }
        &-logo {
            height: 100%;
            &:after {
                top: 15px;
            }
        }
        &-gps {
            padding: 10px;
            &:before {
                background-image: url(/common/img/icon_location.png);
                background-size: cover;
                width: 13px;
                height: 20px;
                left: 15px;
            }
        }
        &-body {
            &:before {
                background-image: url(/common/img/icon_body.png);
                background-size: cover;
                width: 26px;
                height: 11px;
            }
        }
        &-front {
            &:before {
                background-image: url(/common/img/icon_front.png);
                background-size: cover;
                width: 23px;
                height: 17px;
                left: 11px;
            }
        }
        &-tire {
            &:before {
                background-image: url(/common/img/icon_tire.png);
                background-size: cover;
                width: 20px;
                height: 20px;
                left: 12px;
            }
        }
        &-headlight {
            &:before {
                background-image: url(/common/img/icon_head_light.png);
                background-size: cover;
                width: 20px;
                height: 20px;
                left: 12px;
            }
        }
        &-indoor {
            &:before {
                background-image: url(/common/img/icon_indoor_dirt.png);
                background-size: cover;
                width: 22px;
                height: 13px;
                left: 10px;
            }
        }
        &-under {
            &:before {
                background-image: url(/common/img/icon_under_dirt.png);
                background-size: cover;
                width: 26px;
                height: 11px;
            }
        }
        &-login {
            &:before {
                background-image: url(/common/img/icon_login02.png);
                background-size: cover;
                width: 25px;
                height: 20px;
                left: 9px;
            }
        }
        &-logout {
            &:before {
                background-image: url(/common/img/ico_logout02.png);
                background-size: cover;
                width: 26px;
                height: 20px;
                left: 7px;
            }
        }
        &-regist {
            &:before {
                background-image: url(/common/img/icon_regist02.png);
                background-size: cover;
                width: 24px;
                height: 23px;
                left: 13px;
            }
        }
        &-mypage {
            &:before {
                background-image: url(/common/img/ico_mypage02.png);
                background-size: cover;
                width: 20px;
                height: 20px;
                left: 12px;
            }
        }
        &-news {
            &:before {
                background-image: url(/common/img/icon_news02.png);
                background-size: cover;
                width: 20px;
                height: 20px;
                left: 12px;
            }
        }
        &-mail {
            &:before {
                background-image: url(/common/img/icon_mail_black.png);
                background-size: cover;
                width: 20px;
                height: 15px;
                left: 12px;
            }
        }
    }
}

.sideOpen {
    .wrapper {
        left: -250px;
        position: fixed;
    }
    .overlay{
        visibility: visible;
        background: rgba(0,0,0,.7);
        transform: translate3d(-250px, 0, 0);
        &:before {
            visibility: visible;
        }
        &:after {
            visibility: visible;
        }
    }
}

.wrapper {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    z-index: 2;
    background: #fff;
}

.overlay {
    content: '';
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 115%;
    background: rgba(0,0,0,0);
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    z-index: 99999;
    &:before {
        content: "×";
        white-space: pre;
        visibility: hidden;
        position: absolute;
        width: 50px;
        height: 50px;
        top: 20px;
        right: 10px;
        display: block;
        color: $color_navy;
        font-size: 2rem;
        text-align: center;
        line-height: 1;
        background-color: #fff;
        -webkit-transition: all .1s ease;
        transition: all .1s ease;
    }
    &:after {
        content: "閉じる";
        visibility: hidden;
        position: absolute;
        width: 50px;
        top: 45px;
        right: 10px;
        display: block;
        color: $color_navy;
        text-align: center;
        -webkit-transition: all .1s ease;
        transition: all .1s ease;
    }
}
